@import "../../assets/styles/colors";

.root {
  width: 920px;
  min-width: 920px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  .back {
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 1.1rem;
    //color: #0093ad;
    color: $primary;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.form {
  width: 100%;
}

$row-height: 2.25rem;

.label {
  line-height: $row-height;
}

.cardBody {
  display: flex;
  flex-direction: column;
}

.dropDown {
  justify-self: stretch;
  align-self: center;
  width: 100%;
}

.confirmationRow {
  grid-column-start: span 2;
  display: flex;
  align-items: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  justify-content: space-between;
  flex-direction: row;

  > label {
    flex: 1;
    padding-right: 3rem;
    box-sizing: border-box;
  }
}

.signatureRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.controls {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  gap: 2rem;

  .submit {
    width: 6rem;
    height: 2.5rem;
  }

  .cancel {
    width: 6rem;
    height: 2.5rem;
    background-color: #ff6961;
    border-color: #ff6961;
  }
}

.exportButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.exportButton {
  min-width: 6rem;
}

.headerItem {
  display: flex;
  flex-direction: row;
}

.exportTextArea {
  white-space: pre-line;
}

.exportContainer {
  position: absolute;
  left: -1000000px;
  top: 0px;
}

.logo {
  width: 166px !important;
  height: 64px !important;
  background-image: url("../../assets//images/acolin.png");
  background-repeat: no-repeat;
  background-size: 166px 64px !important;
}
