@import "../../assets/styles/colors";

.root {
  width: 100%;
}

.loadingContainer {
  height: 20rem;
  width: 100%;
  position: relative;
}

$row-height: 2rem;

.counterpartyName {
  //color: #0093ad;
  color: $primary;
  &:hover {
    //color: #0093ad;
    color: $primary;
    text-decoration: underline;
    //text-decoration-color: #0093ad;
    text-decoration-color: $primary;
  }
}

.gridContainer {
  position: relative;
}

.scrim {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
}
