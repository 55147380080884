.root {
  position: relative;
  width: 1000px;
  min-width: 1000px;

  .grid {
    min-height: 300px;
  }
}

.loadingContainer {
  height: 20rem;
  width: 100%;
  position: relative;
}
