.formRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.formRowContent {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
}

.tableInputError {
  &::placeholder {
    color: red;
  }
}

.radioGroup {
  display: flex;
  flex-direction: row;
}

.checkboxGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  box-sizing: border-box;
}
