.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 7.25in;
  flex-direction: column;
  justify-content: flex-end;
  page-break-after: always;
}

.background {
  background-image: url("../../../../assets/images/cover_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 4in;
  position: absolute;
  bottom: 0.5in;
}

.logo {
  width: 166px;
  height: 64px;

  background-image: url("../../../../assets//images/acolin.png");
  background-repeat: no-repeat;
  //background-size: 240px 64px;
  background-size: 166px 64px;
  position: absolute;
  top: 0.5in;
  left: 0;
}

.title {
  position: absolute;
  top: 1.5in;
  left: 1in;
}

.subtitle {
  position: absolute;
  top: 2.6in;
  left: 1in;
  color: black;
}

.breakBefore {
  page-break-before: always;
}
