@import "../../../../src/assets/styles/colors";

.root {
  width: "100%";
  display: flex;
  flex-direction: column;
}

$row-height: auto;
$line-height: 2.25rem;

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: #{$row-height};

  .label {
    line-height: #{$line-height};
    width: 64px;
    min-width: 64px;

    &:last-of-type {
      padding-left: 16px;
    }
  }

  .link {
    line-height: #{$line-height};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .content {
    @extend .link;
    width: 340px;
    max-width: 340px;
  }

  .url {
    @extend .link;
    width: 364px;
    max-width: 364px;

    a:link,
    a:visited,
    a {
      background: transparent;
      //color: #0093ad;
      color: $primary;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        //text-decoration-color: #0093ad;
        text-decoration-color: $primary;
      }
    }
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  height: 4rem;
}

.button {
  margin-left: 1rem;
  width: 4.5rem;
}
