@import "../../../assets/styles/colors";

.root {
  position: relative;
  margin-bottom: 1rem;
  scroll-margin-top: 10px;

  .pageBreakToggle {
    position: absolute;
    right: -2rem;
    top: 0;
    bottom: 0;
  }

  h3 {
    //color: #0093ad;
    color: $primary;
  }

  hr {
    display: block;
    height: 1px;
    background: transparent;
    width: 100%;
    border: none;
    //border-top: solid 1px #0093ad;
    border-top: solid 1px $primary;
  }
}

.break {
  page-break-before: always;
}

.disabled {
  text-decoration: line-through;
}

@media print {
  .pageBreakToggle {
    display: none;
  }
}
