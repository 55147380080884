@import "../../../assets/styles/risk";

$row-height: 2.25rem;

.tableRow {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  box-sizing: border-box;

  th {
    display: grid;
    justify-content: center;
    align-items: center;

    border-style: solid;
    border-radius: 1rem;
    border-color: $secondary;
    border-width: thin;

    margin: 0.2rem;
  }

  td {
    display: grid;
    justify-content: center;
    align-items: center;

    border-style: solid;
    border-radius: 1rem;
    border-color: $primary;
    border-width: thin;

    margin: 0.2rem;
  }
}

.hoverDetails {
  color: $secondary;
  //background-color: $secondary;

  text-align: left;
  padding-left: 1rem;

  border-style: dashed;
  border-radius: 1rem;
  border-color: $secondary;
  border-width: thin;

  // margin: 0.2rem;
}

.hoverRow {
  margin-top: 1rem;
  min-height: 6rem;
}

.requiredTableField {
  color: red;
}

.requiredUBOList {
  margin-top: 3rem;
  color: red;
  text-align: center;
}
