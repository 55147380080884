@import "../../../assets/styles/risk";

$row-height: 2.25rem;

.tableRow {
  display: grid;
  grid-template-columns: 1fr 3.5fr 2fr 1.5fr 2.25fr 1.5fr;
  grid-auto-rows: auto;
  box-sizing: border-box;

  th {
    white-space: pre;
    display: flex;
    justify-content: center;
    align-items: center;

    &:nth-child(2) {
      justify-content: flex-start;
      padding-left: 1rem;
    }
  }

  td {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    line-height: $row-height;

    &:nth-child(2) {
      align-items: flex-start;
      padding-left: 1rem;
    }

    &:nth-child(4) {
      justify-content: flex-end;
    }

    &:nth-child(5) {
      font-weight: bold;
    }

    input {
      text-align: center;
    }
  }
}

.arrLabel {
  font-size: 1.4rem;
  font-weight: bold;
  width: 100%;
  position: absolute;
}

.arrValue {
  font-size: 1.4rem;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 1rem;
}
