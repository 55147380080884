.root {
  width: 100%;
}

.gridContainer {
  position: relative;

  .grid {
    min-height: 300px;
  }
}

.scrim {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
}
