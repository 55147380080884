// TODO Refactor after moving parts of
// QCOR to separate modules
@import "../../assets/styles/colors";

.root {
  width: 1000px;
  max-width: 1000px;

  a:link,
  a:visited,
  a {
    background: transparent;
    //color: #0093ad;
    color: $primary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      //text-decoration-color: #0093ad;
      text-decoration-color: $primary;
    }
  }

  p {
    width: 100%;
    box-sizing: border-box;
    padding-left: 25px;
    text-align: justify;
    text-justify: inter-word;
  }
}

.title {
  display: block;
}

.loadingContainer {
  height: 20rem;
  width: 100%;
  position: relative;
}

.header {
  position: relative;
  height: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.headerLogo {
  width: 166px !important;
  height: 64px !important;
  background-image: url("../../assets//images/acolin.png");
  background-repeat: no-repeat;
  background-size: 166px 64px !important;
}

.headerNote {
  position: absolute;
  font-size: 0.75rem;
  display: none;
  bottom: 0.25in;
  right: 0;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  gap: 2rem;
}

.reportTitle {
  //color: #0093ad;
  color: $primary;
  width: 100%;
}

.table {
  width: 100%;
  border-spacing: 0;
  margin-bottom: 1.5rem;

  thead {
    color-adjust: exact;
    //background-color: #0093ad;
    background-color: $primary;
    tr {
      color: white;
      border-width: 0 0px 1px 1px;
      border-style: solid;
      //border-color: #0093ad;
      border-color: $primary;
      text-align: center;
      padding: 0;

      &:last-of-type {
        border-right-width: 1px;
      }
    }
  }

  tbody {
    tr {
      td {
        position: relative;
        border-width: 0 0px 1px 1px;
        border-style: solid;
        //border-color: #5cb9cb;
        border-color: $primary;
        text-align: center;
        padding: 0;

        &:last-of-type {
          border-right-width: 1px;
        }

        input {
          width: 95%;
          border-color: transparent;
          outline: none;
          font-family: "RotisSansSerifPro", sans-serif;
          font-size: 1rem;
          height: 1rem;
        }
      }

      .index {
        width: 2.5rem;
      }
    }
  }
}

.subtitle {
  font-weight: bold;
}

// TODO Make this more specific in Target Market
// and Swiss Distributors Monitoring
ol {
  padding-left: 4rem;
}

// TODO Make this more specific in Due Dilligence Process
ul {
  padding-left: 4rem;
}

.inputRow {
  position: relative;
}

.add {
  position: absolute;
  width: 1.5rem;
  right: 0;
  top: 0;
  bottom: 0;
  margin-right: -2rem;
}

.remove {
  position: absolute;
  width: 1.5rem;
  right: 0;
  top: 0;
  bottom: 0;
  margin-right: -2rem;
}

.whiteBackground {
  background-color: white;
}

.darkGreenBackground {
  background-color: #54a738;
}

.greenBackground {
  background-color: #99ff33;
}

.lightGreenBackground {
  background-color: #ccff66;
}

.yellowBackground {
  background-color: #ffff66;
}

.orangeBackground {
  background-color: #ffcc00;
}

.darkOrangeBackground {
  background-color: #ff9933;
}

.darkerOrangeBackground {
  background-color: #ff6600;
}

.redBackground {
  background-color: #ff0000;
}

@media print {
  .title {
    display: none;
  }

  .header {
    height: 0.75in !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .headerLogo {
    //width: 180px !important;
    //height: 48px !important;
    width: 166px !important;
    height: 64px !important;
    background-image: url("../../assets//images/acolin.png");
    background-repeat: no-repeat;
    //background-size: 180px 48px !important;
    background-size: 166px 64px !important;
  }

  .headerNote {
    display: block;
  }

  .inputRow {
    display: none;
  }

  .remove {
    display: none;
  }

  .title {
    color: #595959;
  }

  .controls {
    display: none;
  }

  .tableOfContents {
    a:link,
    a:visited,
    a {
      background: transparent;
      color: #080000;
      font-weight: bold;
      text-decoration: none;
      text-align: left;
      line-height: 1.75rem;
    }
  }

  .table {
    tbody {
      tr {
        td {
          input {
            &::placeholder {
              color: transparent;
            }
          }
        }
      }
    }
  }
}
