@import "../../../src/assets/styles/colors";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.title {
  display: block;
}

.printButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  height: 3rem;
}

.tableContainer {
  width: 100%;
  overflow: auto;
}

$cell-padding: 5px;

.table {
  border-spacing: 0;

  thead {
    color-adjust: exact;
    //background-color: #0093ad;
    background-color: $primary;
    color: white;

    tr {
      padding: 0;

      th {
        vertical-align: bottom;
        text-align: center;
        border-width: 0 0px 1px 1px;
        border-style: solid;
        //border-color: #5cb7c7;
        border-color: $primary;

        .topSubHeader {
          //border: 0 solid #5cb7c7;
          border: 0 solid $primary;
          border-bottom-width: 1px;
        }

        .bottomSubHeader {
          display: flex;
          flex-direction: row;
        }

        .bottomSubHeaderCell {
          //border: 0 solid #5cb7c7;
          border: 0 solid $primary;
          border-right-width: 1px;

          &:last-of-type {
            border-right-width: 0;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        min-width: 100px;
        border-width: 0 0px 1px 1px;
        border-style: solid;
        //border-color: #5cb7c7;
        border-color: $primary;
        padding: 0;
        text-align: center;

        &:last-of-type {
          border-right-width: 1px;
        }
      }
    }
  }

  span {
    padding-left: $cell-padding;
    padding-right: $cell-padding;
  }

  .wideColumn {
    min-width: 150px;
    width: 150px;
  }
}

@media print {
  .root {
    width: 100% !important;
    overflow: unset !important;
  }

  .title {
    display: none;
  }

  .printButtonContainer {
    display: none;
  }

  .table {
    width: 100%;

    tbody {
      tr {
        td {
          width: unset !important;
          min-width: unset !important;
        }
      }
    }
  }
}
