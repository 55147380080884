@import "../../../src/assets/styles/colors";

.loadingContainer {
  height: 20rem;
  width: 100%;
  position: relative;
}
.cardBody {
  display: flex;
  flex-direction: row;
  width: 888px;
}

.footer {
  display: flex;
  //align-items: center;
  justify-content: center;
}

.button {
  width: 555px;
}
//
.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.5rem;
  //min-width: 920px;
  width: 888px;
}

.item {
  display: flex;
  flex-direction: row;

  span {
    font-size: 1rem;
    line-height: 1.6rem;
    &:first-child {
      margin-right: 0.5rem;
    }
    &:not(:first-child) {
      display: inline-block;
      font-weight: bold;
      white-space: pre-line;
      color: #444444;
    }
  }

  br {
    content: " ";
    display: block;
    margin: 0.1rem 0;
  }
}

.vertical {
  flex-direction: column;
}

.fullWidth {
  grid-column-start: span 2;
}

.indentContent {
  span {
    &:not(:first-child) {
      text-indent: 1rem;
    }
  }
}

.link {
  a:link,
  a:visited,
  a {
    background: transparent;
    //color: #0093ad;
    color: $primary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      //text-decoration-color: #0093ad;
      text-decoration-color: $primary;
    }
  }
}

dd {
  font-weight: bold;
}
