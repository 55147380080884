.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  width: 30rem;
  display: flex;
  justify-content: center;
}

.buttonContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
}
