.text {
  text-align: center;
  padding: 1rem;
  font-size: 1rem;
}

.controls {
  display: flex;
  justify-content: space-around;
  align-items: center;

  .button {
    width: 7rem;
    height: 2.5rem;
  }
}
