@import "../../QCORTemplate.module.scss";

.initialDDColumn {
  width: 100px;
  min-width: 100px;
}

.chartsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
  page-break-inside: avoid;
}

.chartContainer {
  flex: 1;
}
