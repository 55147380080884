@import "../../assets/styles/risk";

.root {
  width: 1000px;
  max-width: 1000px;
}

.controls {
  display: flex;
  justify-content: flex-end;
}

$border-color: #ebebeb;

.ddcHistoryTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $border-color;

  %cell {
    padding: 8px 12px;
    text-align: start;
    border: 1px solid $border-color;
  }

  th {
    @extend %cell;
    font-size: 1.05rem;
  }

  td {
    @extend %cell;
    font-size: 1rem;
  }
}
