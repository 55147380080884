@import "../../../src/assets/styles/colors";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 920px;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.cardBody {
  display: flex;
  flex-direction: column;
  width: 888px;
}

.loadingContainer {
  height: 20rem;
  width: 100%;
  position: relative;
}

.search {
  flex: 1;

  span {
    height: 100%;
  }

  span[role="button"] {
    height: 28px;
    line-height: 28px;
  }
}

.select {
  margin-left: 32px;
}

.invalidField {
  color: red;
}

.possibleValues {
  display: grid;
  color: $secondary;
  grid-template-columns: repeat(4, 1fr);
  //gap: 30px;
  // grid-auto-rows: 50px;

  //padding: 20px;
}

.possibleValue {
  border: 0.5px solid $secondary;
  display: flex;
  // justify-content: center;
  justify-content: space-between;
  // text-align: center;
  padding: 10px;
}
