.notification-group {
  right: 0;
  bottom: 0;
  align-items: flex-start;
  flex-wrap: wrap-reverse;

  a {
    color: color("white");
    font-weight: bold;
  }
}
