@import "../../../../assets/styles/colors";

.root {
  page-break-after: always;

  a:link,
  a:visited,
  a {
    background: transparent;
    //color: #0093ad;
    color: $primary;
    font-weight: bold;
    text-decoration: none;
    text-align: left;
    line-height: 2.25rem;

    &:hover {
      text-decoration: underline;
      //text-decoration-color: #0093ad;
      text-decoration-color: $primary;
    }
  }
}
