.k-card {
  border-color: transparent;
  color: #262d34;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 10px rgba(38, 45, 52, 0.15);
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 1rem;
  margin: 1rem 0;
}

.k-textbox {
  border-radius: 1.125rem;
  line-height: 2.25rem;
  padding: 0.375rem 0.75rem;
  height: 2.25rem;
  width: 100%;
  border: 1px solid $primary;
  color: $secondary;
  font-size: 1rem;

  &:focus {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 8px 0 rgba(0, 147, 173, 0.4);
  }

  &:hover {
    border: 1px solid $primary;
  }
}

.k-textarea {
  border-radius: 1.125rem;
  height: auto;
  width: 100%;
  border: 1px solid $primary;
  color: $secondary;
  font-size: 1rem;
  //resize: none;

  &:focus {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 8px 0 rgba(0, 147, 173, 0.4);
  }

  &:hover {
    border: 1px solid $primary;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  & > .k-input {
    //resize: none;
    &:disabled {
      opacity: 0.6;
    }
  }
}

.k-checkbox {
  border-color: $primary;

  &:hover {
    border-color: $tertiary;
  }
}

.k-form-error {
  display: inline;
  height: 1rem;
  color: red;
  font-size: 1rem;
  line-height: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.2rem;
  margin-right: 0.25rem;
  width: 100%;
  text-align: right;
  font-style: normal;
}

.k-dropdown {
  width: inherit;
}

.k-notification-wrap {
  font-size: 1.1rem;
  align-items: center;
}

.k-i-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 1.5rem;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
}

.k-grid {
  .k-grid-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .k-grid-header {
    background-color: transparent;
    padding: 0 !important;
  }

  .k-header {
    font-weight: bold;
  }

  .k-grid-content {
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .k-grid-pager {
    padding: 0.5rem 0;
    background-color: transparent;
  }

  .k-link {
    font-size: 1.05rem;
  }

  .k-master-row {
    cursor: pointer;
    height: 2rem;
  }

  tr {
    color: #262d34;
  }

  td {
    box-sizing: border-box;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    color: #262d34;
  }
}

.k-panelbar {
  > .k-item {
    &.k-state-active > .k-link,
    &.k-state-expanded > .k-link {
      font-weight: bold;
    }

    > .k-link {
      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;

      &.k-state-selected:hover,
      &.k-state-selected.k-state-hover {
        background-color: transparent;
      }
    }
  }
}

.k-multiselect {
  .k-multiselect-wrap {
    .k-button {
      border-radius: 0;
      min-height: 1.5rem;
      max-height: 1.5rem;
      height: 1.5rem;
    }
  }
}

.k-daterangepicker {
  .k-label {
    display: none;
  }
  .k-textbox-container {
    padding-top: 0;
    width: 10.85rem;
  }
}

.k-button {
  min-height: inherit;
  min-width: inherit;
  background-image: linear-gradient(
    rgba(92, 158, 199, 0),
    rgba(92, 158, 199, 0.2)
  );
  //border-color: #5cb7c7;
  border-color: $secondary;
  color: #fff;
  //background-color: #5cb7c7;
  background-color: $secondary;
  font-size: 1rem;
  background-clip: padding-box;
  border-radius: 1.125rem;
  padding: 0.375rem 1.5rem;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  line-height: 1.5;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -webkit-appearance: none;
}

.k-pdf-export {
  font-family: "museo-sans-regular", sans-serif;

  dd {
    padding-right: 150px;
  }

  .k-textarea {
    .k-input {
      padding-left: 20px;
      padding-right: 150px;
      word-break: normal;
    }
  }
}
