@import "../../../../assets/styles/risk";

$height: 2rem;

.root {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  height: $height;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 1.6rem;
}

.label {
  text-align: right;
  line-height: $height;
}

.value {
  text-align: left;
  line-height: $height;
}
