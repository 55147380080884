.root {
  width: 800px;
  min-width: 800px;

  .commentsCardBody {
    padding-top: 0;
    padding-bottom: 20px;
  }
}

.loadingContainer {
  height: 20rem;
  width: 100%;
  position: relative;
}

.comments {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

$row-height: 2.25rem;

.table {
  width: 100%;
}

.tableRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  box-sizing: border-box;

  th {
    display: flex;
    margin: 0 1.5rem;
  }

  td {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    line-height: $row-height;
    margin: 0 1.5rem;
  }
}

.label {
  line-height: $row-height;
  height: $row-height;
  max-height: $row-height;
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
}
