.root {
  width: 1000px;
  min-width: 1000px;

  .commentsCardBody {
    padding-top: 0;
    padding-bottom: 20px;
  }
}

$row-height: 2.25rem;

.table {
  width: 100%;
}

.tableRow {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr;
  grid-auto-rows: auto;
  box-sizing: border-box;

  th {
    white-space: pre;
    display: flex;
    justify-content: center;
    align-items: center;

    &:nth-child(2) {
      justify-content: flex-start;
      padding-left: 1rem;
    }
  }

  td {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    line-height: $row-height;
    margin: 0 1.5rem;

    &:nth-child(1) {
      align-items: center;
    }

    &:nth-child(2) {
      align-items: flex-start;
    }

    input {
      text-align: center;
    }

    &.sumLabel {
      margin: 0;
      align-items: flex-end;
      font-weight: bold;
      font-size: 1.2rem;
      margin-top: 1rem;
    }

    &.sumValue {
      font-weight: bold;
      font-size: 1.2rem;
      margin-top: 1rem;
      color: green;
    }
  }
}

.error {
  color: red;
}

.label {
  line-height: $row-height;
  height: $row-height;
  max-height: $row-height;
}

.tableFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.footerInputContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 13rem;
  gap: 20px;
}

.footerInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  input {
    text-align: center;
  }
}

.comments {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
}
