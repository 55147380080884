@import "../styles/colors";
//TODO Move vars to partial CSS
// old colors
// $primary: #0093ad;
// $secondary: #262d34;
// $tertiary: #5cb7c7;

// new colors
// $primary: #d89f5a;
// $secondary: #7d8f80;
// $tertiary: #202e42;
// // not used currently
// $text: #172b4d;

// Old fonts
// @font-face {
//   font-family: "RotisSansSerifPro";
//   src: url("../fonts/RotisSansSerifPro.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-swap: swap;
// }

// @font-face {
//   font-family: "RotisSansSerifPro-Bold";
//   src: url("../fonts/RotisSansSerifPro-Bold.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-swap: swap;
// }

// @font-face {
//   font-family: "RotisSansSerifPro-Italic";
//   src: url("../fonts/RotisSansSerifPro-Italic.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-swap: swap;
// }

// New fonts
@font-face {
  font-family: "museo-sans-regular";
  src: url("../fonts/museo-sans-regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-swap: swap;
}

@font-face {
  font-family: "museo-sans-bold";
  src: url("../fonts/museo-sans-bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-swap: swap;
}

@font-face {
  font-family: "museo-sans-light";
  src: url("../fonts/museo-sans-light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-swap: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  //font-family: "RotisSansSerifPro", sans-serif;
  font-family: "museo-sans-regular", sans-serif;
}

h1 {
  font-size: 2.25rem;
  font-weight: lighter;
  line-height: 3rem;
  padding: 1.5rem 0;
  margin: 0;
  box-sizing: border-box;
  color: $primary;
}

h2 {
  line-height: 1.5rem;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  color: #262d34;
}

h3 {
  line-height: 1.1rem;
  margin: 0;
  font-size: 1.1rem;
  font-weight: 700;
  color: #262d34;
}

$scrollbar-size: 9px !default;
$scrollbar-background: #eee !default;
$scrollbar-thumb: #999 !default;
$scrollbar-thumb-hover: #777 !default;

::-webkit-scrollbar {
  width: $scrollbar-size;
}

::-webkit-scrollbar-track {
  background-color: $scrollbar-background;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: $scrollbar-thumb;
  transition: background-color 0.2s linear;
  will-change: background-color;

  &:hover {
    background-color: $scrollbar-thumb-hover;
  }
}
