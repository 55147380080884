.reload {
  display: flex;
  align-items: center;

  &__icon {
    margin: 0 0.75rem 0 -1rem;
  }

  &__message {
    margin: 0 1rem 0 0;
  }

  &__button {
    margin-right: -1rem;
  }
}
