.root {
  width: 1000px;
  min-width: 1000px;
}

.loadingContainer {
  height: 20rem;
  width: 100%;
  position: relative;
}
