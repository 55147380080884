@import "../../QCORTemplate.module.scss";

.nonEUHeader {
  //background-color: #0093ad;
  background-color: $primary;
  color: white;
  text-align: left;
  font-weight: bold;
  padding-left: 0.5rem;
}

/* Older CSS atribute page-break-before is used
 instead of the newer break-before because of 
 it's inconsistent support. See more:
 https://developer.mozilla.org/en-US/docs/Web/CSS/break-before
*/

.mapContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  page-break-before: always;
}

.map {
  width: 1000px;
  height: 504px;
  margin-top: 50px;
  background-image: url("../../../../assets/images/distribution_network_map.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.chartContainer {
  page-break-before: always;
}
