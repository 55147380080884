// old colors
// $primary: #0093ad;
// $secondary: #262d34;
// $tertiary: #5cb7c7;

// new colors
$primary: #d89f5a;
$secondary: #7d8f80;
$tertiary: #202e42;
// not used currently
$text: #172b4d;

$risk-red: red;
$risk-green: green;
$risk-yellow: #ffb300;
