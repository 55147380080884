@import "../../assets/styles/colors";

.root {
  display: grid;
  grid-template-columns: calc(max(10%, 10rem)) 80% calc(max(10%, 10rem));
  grid-template-rows: 6.5rem auto;
  width: 100%;
}

.header {
  grid-column-start: 1;
  grid-column-end: 4;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 15rem;
  height: 4.5rem;
}

.userContainer {
  display: flex;
  margin: 0 2rem;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.logOut {
  background-color: #262d34;
  height: 3rem;
  width: 3rem;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 2rem;
  cursor: pointer;
}

.sidebar {
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}

menu {
  padding-inline-start: 1rem;

  li {
    &::marker {
      content: "";
    }

    a {
      color: #262d34;
      text-decoration: none;
    }
  }
}

.activeNav {
  //color: #0093ad;
  color: $primary;
}

.content {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media print {
  .root {
    display: grid;
    grid-template-columns: 0 auto 0;
    grid-template-rows: 0 auto;
  }

  .header {
    display: none;
  }

  .sidebar {
    display: none;
  }
}
