@import "./colors";

.riskHigh {
  color: $risk-red;
}

.riskMedium {
  color: $risk-yellow;
}

.riskLow {
  color: $risk-green;
}

.riskNotCalculated {
  color: inherit;
}
