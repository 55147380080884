$row-height: 2rem;

.filter {
  width: 100%;
  display: flex;
  justify-content: center;
}

.filterContent {
  max-width: 94.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.5rem;
}

.filterItem {
  display: flex;
  flex-direction: row;
  //width: 31.5rem;
  width: 33rem;
  padding-right: 2rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: flex-start;

  .filterItemLabel {
    //width: 7.5rem;
    width: 9rem;
    flex-shrink: 0;
    flex-grow: 0;
    height: $row-height;
    line-height: $row-height;
    display: flex;
    align-items: center;
  }

  .arrInput {
    width: 5rem;
    height: $row-height;
    text-align: center;
  }

  .arrLabel {
    width: 5rem;
    height: $row-height;
    line-height: $row-height;

    &:last-of-type {
      margin-left: 2rem;
    }
  }

  .resetFilterItem {
    margin-left: 0.5rem;
    border-color: rgba(0, 0, 0, 0.08);
    color: #656565;
    background-color: #f6f6f6;
    background-image: linear-gradient(#f6f6f6, #f1f1f1);
    border-radius: 2px;
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    text-decoration: none;
    height: 1.9rem;
    line-height: 1.9rem;
  }
}

.filterButtonsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .filterButton {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
