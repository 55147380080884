@import "../../assets/styles/colors";

.logGrid {
  .editLogChangeset {
    h4 {
      padding-left: 0;
      text-align: center;
    }

    dl {
      display: grid;
      row-gap: 1rem;
      column-gap: 2rem;
    }

    .twoColumns {
      grid-template-columns: 1fr 1fr;
    }

    .threeColumns {
      grid-template-columns: 1fr 2fr;
    }

    dt {
      font-weight: bold;
      text-align: right;
      white-space: normal;
    }
    dd {
      margin-left: 0;
      min-width: 0;
      white-space: normal;
      overflow-wrap: break-word;
    }
  }
}

.green {
  color: $secondary;
}
